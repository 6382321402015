<template>
    <v-form v-model="formValid">
        <gusa-container container-class="pt-0">
            <div style="display: flex; flex-direction: row">
                <img style="width: 130px; margin-bottom: 1%" src="../../../assets/logos/getac-logo.svg" />
                <div class="text-h7 nav-font pl-3" style="margin-top: 1.3%">DEVICE UPGRADE</div>
            </div>
            <v-divider style="margin-bottom: 1%; border-color: gray" />
            <div class="first-half-container" style="display: flex; flex-direction: row">
                <div id="upgrade-card-left" style="width: 50%">
                    <v-card elevation="0" dark color="transparent">
                        <v-toolbar flat color="transparent" dark>
                            <h1 style="font-size: 36px; letter-spacing: -0.08rem">Report Device Problem</h1>
                        </v-toolbar>
                        <p style="padding: 4px 16px; margin-top: -10px">Please specify the product issues below.</p>
                        <v-col cols="12">
                            <v-textarea
                                v-model="formInput.problemDescription"
                                dark
                                outlined
                                style="width: 95%"
                                height="250"
                                name="problem"
                                label="Upgrade Notes..."
                                counter
                                maxlength="1000"
                                :rules="[formRules.required, formRules.maxLength(1000, 'characters')]"
                            ></v-textarea>
                        </v-col>
                    </v-card>
                </div>
                <v-divider vertical style="border-color: rgba(33, 33, 33, 1) !important" />
                <div id="upgrade-card-right" style="width: 50%">
                    <v-card elevation="0" dark color="transparent">
                        <h1
                            style="
                                font-size: 24px;
                                letter-spacing: -0.03rem;
                                padding: 4px 16px;
                                margin-bottom: 5px;
                                margin-top: 15px;
                            "
                            >Windows Login and Password</h1
                        >
                        <p style="padding: 4px 16px; margin-top: -10px"
                            >Providing customer windows login and password allows the service technicians to test this
                            product with the original hard drive. The login credentials will only be viewed by the
                            service technicians. It would be considered best practice to change the password to
                            something generic. Please be aware, should the windows login/password not be provided, it
                            may lead to delays in processing your service request/upgrade/repair.</p
                        >
                    </v-card>
                    <v-card elevation="0" dark color="transparent" style="margin-top: 35px">
                        <v-col cols="12">
                            <v-text-field
                                v-model="formInput.userId"
                                label="Windows Username (Optional)"
                                outlined
                                dark
                            ></v-text-field>
                            <v-text-field
                                v-model="formInput.userPassword"
                                label="Windows Password (Optional)"
                                outlined
                                dark
                            ></v-text-field>
                        </v-col>
                    </v-card>
                </div>
            </div>
            <div>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            style="margin-left: 15px; margin-right: 15px; color: #db5120"
                            dark
                            elevation="0"
                            outlined
                            v-bind="attrs"
                            v-on="on"
                        >
                            Disclaimer
                        </v-btn>
                    </template>
                    <span style="width: 100px"
                        >To protect your data from any loss and damages, you shall back up your personal data before
                        returning the Product(s). Getac assumes no liability for any loss or damages of data in
                        connection with, or arising out of, the services (including but not limited to test performance
                        and repair) you hereby request.</span
                    >
                </v-tooltip>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            style="margin-left: 15px; margin-right: 15px; color: #db5120"
                            dark
                            elevation="0"
                            outlined
                            v-bind="attrs"
                            v-on="on"
                        >
                            Notice
                        </v-btn>
                    </template>
                    <span style="width: 100px"
                        >The costs for out-of-warranty repair revealed hereunder are for estimate only. To resume
                        product normal function, some additional services may be required, and therefore Getac therefore
                        Getac will suspend all repairs until your further instructions are made. Please be advised that
                        such additional services may require extra payment, and you may request Getac to return the
                        Product if Product if you select not to process further..</span
                    >
                </v-tooltip>
            </div>
            <v-divider class="mt-5 mb-5" style="border-color: gray" />
            <div class="btns-container" style="justify-content: space-between; margin-top: 20px; padding: 0px 20px">
                <v-btn color="primary" elevation="0" outlined @click="$emit('back')">Back</v-btn>
                <div class="right-side-btns" style="float: right">
                    <v-btn
                        color="primary"
                        elevation="0"
                        style="margin-left: 25px"
                        :disabled="!formValid"
                        @click="
                            $emit('continue')
                            emitInput()
                        "
                        >Next</v-btn
                    >
                </div>
            </div>
        </gusa-container>
    </v-form>
</template>

<script>
import GusaContainer from '@/layouts/home/container.vue'
import formRules from '@/utils/form_rules'

export default {
    name: 'ProblemDescription',
    components: { GusaContainer },
    props: {
        quoteDesc: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            formInput: {
                problemDescription: ''
            },
            formRules: formRules,
            formValid: false
        }
    },
    watch: {
        quoteDesc: {
            immediate: true,
            handler(v) {
                if (v !== ``) {
                    this.formInput.problemDescription = v
                }
            }
        }
    },
    methods: {
        async emitInput() {
            await this.$nextTick()
            this.$emit(`deviceInput`, this.formInput)
        }
    }
}
</script>
